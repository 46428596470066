import { useEffect, useState } from 'react'
import '../main.css'
import axios from 'axios'
import { getConfig } from '../config/getConfig'
import Spotify from '../images/svg/Spotify'
import Applemusic from '../images/svg/Applemusic'
import Youtube from '../images/svg/Youtube'
import Amazon from '../images/svg/Amazon'
import AmazonMusic from '../images/svg/AmazonMusic'
import Anghami from '../images/svg/Anghami'
import Boomplay from '../images/svg/Boomplay'
import Deezer from '../images/svg/Deezer'
import Itunes from '../images/svg/Itunes'
import Pandora from '../images/svg/Pandora'
import Tidal from '../images/svg/Tidal'
import YoutubeMusic from '../images/svg/YoutubeMusic'
import backgroundImage from '../images/blur_winter.webp'
import links from '../config/linksObject'

const config = getConfig()

const bandLogo = "https://ugc.production.linktr.ee/26fa80fa-82fd-4c28-b233-a2b47d452144_BAND---Final---FIX---Extended---MEGA-COMPRESSED.png?io=true&size=avatar-v1_0"
// const allPlatforms = ['amazon', 'amazonmusic', 'anghami', 'applemusic', 'boomplay', 'deezer', 'itunes', 'pandora', 'spotify', 'tidal', 'youtube', 'youtubemusic']

export default function Main() {

    const [showPopup, setshowPopup] = useState(false)
    const [popupData, setpopupData] = useState({})

    const searchParams = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (let [key, value] of searchParams) { paramsObject[key] = value; }

    async function countVisitors() {
        try {
            axios.get(`${config.backend}/countVisitors?o=${paramsObject.o}&p=links`)
        } catch (error) { }
    }
    async function sendClick(data, platform = null) {
        const dataCopy = JSON.parse(JSON.stringify(data))
        try {
            if (data.type === 'multi') {
                dataCopy.link = data.links[platform]
                delete dataCopy.links
                dataCopy.platform = platform
            }
            const body = { link: dataCopy.link, song_name: dataCopy.song_name, platform: dataCopy.platform, o: paramsObject.o }
            axios.post(`${config.backend}/click`, body)
        } catch (error) { }
    }

    useEffect(() => {
        countVisitors()
    }, [])

    // return (<div className='bg_full' style={{}}>
    return (<div className='bg_full_single' style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', }}>
        <div className={`popup_bg`} style={{ zIndex: showPopup ? 100 : -999, opacity: showPopup ? 1 : 0 }} onClick={() => setshowPopup(false)}>
            {showPopup && <div className='popup' onClick={(e) => e.stopPropagation()}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={popupData.image} alt={popupData.title + "_img_popup"} width={"50%"} style={{ marginRight: '1rem', }} />
                    <h2 style={{ textAlign: 'center' }}>{popupData.band_name} - {popupData.song_name}</h2>
                </div>
                <hr style={{ width: "100%", marginBottom: "3vh" }} />
                <div className='multi_links'>
                    {Object.keys(popupData.links).map(platform => <a className="singleLink" onClick={e => sendClick(popupData, platform)} href={popupData.links[platform]}>
                        {getPlatformLogo(platform, 45, 45)}
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{getPrettyName(platform)}</div>
                    </a>)}
                </div>
            </div>}
        </div>
        <div style={{ alignSelf: 'center', }}>
            {/* <h1>Her Last Sight</h1> */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <img src="https://static.wixstatic.com/media/b59144_9ede0b40946b43e68439cb4a5b18ccf1~mv2.png/v1/fill/w_775,h_189,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LOGO%20STRAIGHT_edited.png" style={{ maxWidth: "80%", height: 'auto' }} alt="" />
            </div>
        </div>

        <div className='header_band'>
            <div style={{ alignSelf: 'center' }}>
                <img src={bandLogo} alt={"band_img"} width={100} style={{ borderRadius: '300px', marginBottom: "1vh", boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 9px 0px' }} />
            </div>


            <div className='header_band_description'>
                {/* Our latest album "PICTURE PERFECT" - Available NOW!<br/> */}
                {/* New Single "SCARLET" - Dropping in 15.03!<br/> */}
                ____________________________________________
            </div>
        </div>
        {links.map(linkData => buildLink(linkData, sendClick, setshowPopup, setpopupData))}

        <footer>
            Her Last Sight 2024 ©
        </footer>
    </div>)
}

function buildLink(linkData, sendClick, setshowPopup, setpopupData) {
    switch (linkData.type) {
        case 'multi':
            return <div onClick={e => { setshowPopup(true); setpopupData(linkData) }} className="link_box" style={{ marginBottom: "2vh" }}>
                <img src={linkData.image} alt={linkData.title + "_img"} width={60} height={60} style={{ marginRight: '1rem' }} />
                <div style={{ display: 'flex', alignItems: 'center', width: '90vw' }}>
                    <div className='text_in_click_box'>
                        {linkData.new ? <div>
                            <b style={{ fontSize: "130%" }}>{linkData.title}</b> <br />
                            <small> <b>*NEW*</b></small>
                        </div> : linkData.title}
                    </div>
                </div>
            </div>
        case 'single':
            return <a href={linkData.link} onClick={e => sendClick(linkData)} className="link_box" style={{ marginBottom: "2vh" }}>
                {linkData.svgImage ? linkData.image : <img src={linkData.image} alt={linkData.title + "_img"} width={"60px"} height={"60px"} style={{ marginRight: '1rem' }} />}
                <div style={{ display: 'flex', alignItems: 'center', width: '90vw' }}>
                    <div className='text_in_click_box'>
                        {linkData.new ? <div>
                            <b style={{ fontSize: "130%" }}>{linkData.title}</b> <br />
                            <small> <b>*NEW*</b></small>
                        </div> : linkData.title}
                    </div>
                </div>
            </a>
        default:
            return
    }
}

function getPlatformLogo(platform, width, height) {
    switch (platform) {
        case 'amazonmusic':
            return <AmazonMusic width={width} height={height} />
        case 'amazon':
            return <Amazon width={width} height={height} />
        case 'anghami':
            return <Anghami width={width} height={height} />
        case 'applemusic':
            return <Applemusic width={width} height={height} />
        case 'boomplay':
            return <Boomplay width={width} height={height} />
        case 'deezer':
            return <Deezer width={width} height={height} />
        case 'itunes':
            return <Itunes width={width} height={height} />
        case 'pandora':
            return <Pandora width={width} height={height} />
        case 'spotify':
            return <Spotify width={width} height={height} />
        case 'tidal':
            return <Tidal width={width} height={height} />
        case 'youtubemusic':
            return <YoutubeMusic width={width} height={height} />
        case 'youtube':
            return <Youtube width={width} height={height} />
        default:
            return ""
    }
}
function getPrettyName(platform) {
    switch (platform) {
        case 'amazonmusic':
            return "Amazon Music"
        case 'amazon':
            return "Amazon"
        case 'anghami':
            return "Anghami"
        case 'applemusic':
            return "Apple Music"
        case 'boomplay':
            return "Boomplay"
        case 'deezer':
            return "Deezer"
        case 'itunes':
            return "iTunes"
        case 'pandora':
            return "Pandora"
        case 'spotify':
            return "Spotify"
        case 'tidal':
            return "Tidal"
        case 'youtubemusic':
            return "YouTube music"
        case 'youtube':
            return "YouTube"
        default:
            return ""
    }
}